import React from 'react';
import { createContents, htmlToReact } from '@helpers/html-to-react-converter';
import {htmlToReactExcerpt} from '@helpers/html-to-react-excerpt';
import s from './ArticlePost.module.scss';
import {AllWpBanners, IWpArticlePost} from '../../../../types/wordpress.types';
import {PostHeader} from '../../../shared/components/post-header/PostHeader.component';
import {PostContainer} from '../../../shared/components/post-container/PostContainer.component';
import {GatsbyImage} from 'gatsby-plugin-image';
import {AlgoliaMini} from '../../../shared/components/algolia-mini/AlgoliaMini.component';
import {PostShare} from '../../../shared/components/post-share/PostShare.component';
import {Banner} from '../../banner/Banner.component';
import {SimilarPosts} from '../components/SimilarPosts.component';
import {Breadcrumbs} from '../../../shared/components/breadcrumbs/Breadcrumbs.component';
import {useIntl} from 'gatsby-plugin-intl';
import {getLink} from '@helpers/linking.helper';
import {PageType} from '@constants/pageType.constants';
import multilingualSlugs from '../../../../intl/slugs.json';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import { gotoAnchor } from '../../../product/product.helper';
import ContentList from '../components/ContentList.component';

interface IArticlePost {
  article: IWpArticlePost;
  banner: AllWpBanners;
  categoriesPosts: any;
}

const ArticlePost = ({article, banner, categoriesPosts}: IArticlePost) => {

  const intl = useIntl();

  const renderContents = () => {
    const arrayOfContent = createContents(article.articleContent.articleBody);

    if(arrayOfContent && arrayOfContent.length>=1) {
      return ContentList(arrayOfContent);
    }
  };
  
  return (
    <>
      <AlgoliaMini isTopBarVisible={true}/>
      <PostContainer post={article}>
        <Breadcrumbs areCrumbsCentered crumbs={[
          {
            name: intl.formatMessage({id: 'common__knowledgeBase'}),
            path: getLink(intl.locale, PageType.KNOWLEDGE_BASE)
          },
          {
            name: article.categories.nodes[0].slug,
            path: getLink(intl.locale,
              PageType.KNOWLEDGE_BASE) + multilingualSlugs[intl.locale][PageType.CATEGORY] + '/' + article.categories.nodes[0].slug
          },
          {
            name: article.title,
            path: ''
          },
        ]}/>
        <PostHeader>{article.title}</PostHeader>
        {htmlToReactExcerpt(article.excerpt)}

        {article.featuredImage &&
          <GatsbyImage alt={''} image={article.featuredImage.node.localFile.childImageSharp.gatsbyImageData} className={s.articlePost__featuredImage}/>
        }
        {renderContents()}
        {htmlToReact(article.articleContent.articleBody)}
        {article.articleContent.videoYoutube && <div className={s.articlePost__video}>
          <iframe
            className={s.articlePost__iframe}
            src={`https://youtube.com/embed/${article.articleContent.videoYoutube}?playlist=${article.articleContent.videoYoutube}`}>
          </iframe>
        </div>}
        <PostShare author={article.articleContent.author ? article.articleContent.author[0] : null}
                   publishDate={article.date}/>
        <Banner
          banner={banner}
        />
      </PostContainer>
      {categoriesPosts && categoriesPosts.length > 0 && <SimilarPosts categoriesPosts={categoriesPosts}/> }
      <StoreSearch
        header={intl.formatMessage({ id: 'product__storeSearchHeader' })}
        fieldText={intl.formatMessage({ id: 'product__storeSearchPlaceholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export default ArticlePost;
